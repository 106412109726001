import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { arrayUnion } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
import firestoreRepository from "../../repositories/firestoreRepository";
import storageRepository from "../../repositories/storageRepository";
import addImage from "../../images/gallery/addImage.svg";
import addImageActive from "../../images/gallery/addImageActive.svg";
import addImageRejected from "../../images/gallery/addImageRejected.svg";
import { Tooltip } from "@mui/material";

const baseStyleee = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "5px",
  borderWidth: 2,
  borderRadius: 16,
  borderColor: "rgba(61, 56, 56, 0.58)",
  borderStyle: "dashed",
  backgroundColor: "rgba(239, 239, 239, 0.6)",
  color: "#3D3838",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#8F4FFF",
  backgroundColor: "#F1E7FF",
};

const acceptStyle = {
  borderColor: "#8F4FFF",
  backgroundColor: "#F1E7FF",
};

const rejectStyle = {
  borderColor: "#ff1744",
  background: "rgba(255, 23, 68, 0.03)",
};

const Upload = ({
  setUploadProgress,
  setUploadThumbnails,
  setFiles,
  setRemainingTimes,
  entityId,
  isOrgUser,
  reloadCallback,
  renderVideo,
  setRenderVideo,
  sessionId,
  largestSubscriptionId,
  userData,
  isAllowedToWrite,
}) => {
  const [renderImage, setRenderImage] = useState(null);

  const fullStorage = userData && userData.storageEmailLastThreshold === 1;

  const onDrop = useCallback(async (files) => {
    setFiles(files);

    const uploadFile = async (fileItem, documentTypeIdx) => {
      const fileExtension = fileItem.type.slice(6);
      // First, create the document in Firestore
      let assetData;
      let assetType;
      switch (documentTypeIdx) {
        case 1:
          assetData = await firestoreRepository.createImage(
            entityId,
            isOrgUser,
            fileExtension,
            false,
            false
          );
          assetType = "image";
          break;
        case 2:
          assetData = await firestoreRepository.createVideo(
            entityId,
            isOrgUser,
            fileExtension,
            false,
            false
          );
          assetType = "video";
          break;
        default:
          throw new Error("Invalid document type index");
      }

      // Then, add the asset to the session
      const sessionData = {
        [`${assetType}GlobalIds`]: arrayUnion(assetData.globalId),
        modifiedAt: serverTimestamp(),
      };
      await firestoreRepository.updateSession(
        entityId,
        isOrgUser,
        sessionId,
        sessionData,
        false
      );

      const uploadTask = storageRepository.uploadFile(
        entityId,
        assetData.globalId,
        documentTypeIdx,
        fileItem
      );
      const uploadStartTime = new Date().getTime();

      uploadTask.on("state_changed", (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [fileItem.name]: { progress, sessionId: sessionId },
        }));
        documentTypeIdx === 1 ? setRenderImage(0) : setRenderVideo(0);
        if (snapshot.state === "running" && progress > 1) {
          const timeElapsed = new Date().getTime() - uploadStartTime;
          const uploadSpeed = (snapshot.bytesTransferred / timeElapsed) * 1000;
          const bytesRemaining =
            snapshot.totalBytes - snapshot.bytesTransferred;
          const timeRemaining = bytesRemaining / uploadSpeed;
          const minutes = Math.floor(timeRemaining / 60);

          setRemainingTimes((prevTimes) => ({
            ...prevTimes,
            [fileItem.name]: minutes,
          }));
        }
      });

      uploadTask.on("state_changed", {
        complete: async function () {
          try {
            // Add a delay to ensure the thumbnails have been generated
            const delay = 5000;
            let updateData = {
              isInCloudStorage: true,
              modifiedAt: serverTimestamp(),
            };

            const fetchDownloadUrls = async (attempt = 1, maxAttempts = 3) => {
              try {
                const response =
                  await storageRepository.getAssetDownloadUrlsWithDelay(
                    entityId,
                    assetData.globalId,
                    documentTypeIdx,
                    fileExtension,
                    delay
                  );
                return response;
              } catch (err) {
                console.error(
                  `Attempt ${attempt} - Error getting download URLs for asset`,
                  err
                );
                if (attempt < maxAttempts) {
                  return fetchDownloadUrls(attempt + 1, maxAttempts);
                } else {
                  throw err;
                }
              }
            };

            try {
              const response = await fetchDownloadUrls();
              updateData = {
                ...updateData,
                downloadURL: response.downloadUrl,
                thumbnailURL: response.thumbnailDownloadUrl,
                thumbnailSessionURL: response.sessionThumbnailDownloadUrl,
              };
            } catch (err) {
              console.error(
                "Failed to get download URLs after 3 attempts",
                err
              );
            }

            if (documentTypeIdx === 1) {
              await firestoreRepository.updateImage(
                entityId,
                isOrgUser,
                assetData.globalId,
                updateData,
                true
              );
            } else {
              await firestoreRepository.updateVideo(
                entityId,
                isOrgUser,
                assetData.globalId,
                updateData,
                true
              );
            }

            reloadCallback();
          } catch (err) {
            console.error("Error displaying uploaded document: ", err);
          }
          documentTypeIdx === 1
            ? setRenderImage((prev) => prev + 1)
            : setRenderVideo((prev) => prev + 1);
          setRemainingTimes((prevTimes) => {
            const { [fileItem.name]: _, ...rest } = prevTimes;
            return rest;
          });
        },
        error: function (err) {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [fileItem.name]: { error: err.message, sessionId: sessionId },
          }));
          setRemainingTimes((prevTimes) => {
            const { [fileItem.name]: _, ...rest } = prevTimes;
            return rest;
          });
        },
      });
    };

    const uploadPromises = files.map(async (fileItem, i) => {
      setUploadThumbnails((prevThumbnails) => ({
        ...prevThumbnails,
        [fileItem.name]: URL.createObjectURL(fileItem),
      }));

      const documentTypeIdx = fileItem.type === "video/mp4" ? 2 : 1;
      await uploadFile(fileItem, documentTypeIdx);
    });

    await Promise.all(uploadPromises);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (renderImage === 0 || renderVideo === 0) {
        e.preventDefault();
        e.returnValue = "";
        return "Please wait for the files to finish uploading.";
      }
    };

    window.addEventListener("hashchange", handleBeforeUnload);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("hashchange", handleBeforeUnload);
    };
  }, [renderImage, renderVideo, isAllowedToWrite]);

  const acceptedFiles =
    userData && userData.stripeSubscriptionId === 1
      ? { "image/jpeg": [], "image/png": [] }
      : { "image/jpeg": [], "image/png": [], "video/mp4": [] };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: acceptedFiles,
      disabled: fullStorage || !isAllowedToWrite,
    });

  const getStyle = (isFocused, isDragAccept, isDragReject, fullStorage) => ({
    ...baseStyleee,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject || fullStorage || !isAllowedToWrite ? rejectStyle : {}),
  });

  const style = useMemo(
    () => getStyle(isFocused, isDragAccept, isDragReject, fullStorage),
    [isFocused, isDragAccept, isDragReject, fullStorage, isAllowedToWrite]
  );

  return (
    <div>
      <section className="upload-section">
        <Tooltip
          title={
            !isAllowedToWrite
              ? "You don't have the required permissions to upload files"
              : ""
          }
        >
          <div
            {...getRootProps({
              style,
            })}
          >
            <input {...getInputProps()} />
            <div className="upload-div">
              {isFocused || isDragAccept ? (
                <img src={addImageActive}></img>
              ) : isDragReject || fullStorage || !isAllowedToWrite ? (
                <img src={addImageRejected}></img>
              ) : (
                <img src={addImage}></img>
              )}
              <div className="upload-p">
                {largestSubscriptionId === 1
                  ? "  Add images"
                  : "  Add images/videos"}
              </div>
            </div>
            <div>or drop files to upload</div>
            <i className="upload-i">
              {largestSubscriptionId === 1
                ? "We currently support JPEG and PNG."
                : "We currently support JPEG, PNG and MP4."}
            </i>
          </div>
        </Tooltip>
      </section>
    </div>
  );
};

export default Upload;
