import React, { useEffect, useState, useRef } from "react";
import "../../style/dashboard.css";
import Layout from "../Layout";
import { Container } from "@mui/system";
import { useAuth, getAuthData } from "../../context/AuthContext";
import video from "./video.svg";
import camera from "./camera.svg";
import { PieChart } from "@mui/x-charts/PieChart";
import { styled } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts/hooks";
import info from "./info.svg";
import axios from "axios";
import camera_gray from "./camera_gray.svg";
import { CircularProgress, Grid } from "@mui/material";
import error from "./error.svg";
import firestoreRepository from "../../repositories/firestoreRepository";

const StoragePage = () => {
  const { currentUser } = useAuth();

  const StyledTextSize = styled("text")(({ theme }) => ({
    fill: "#3D3838",
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 15,
  }));

  const StyledText = styled("text")(({ theme }) => ({
    fill: "#3D3838",
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
    fontWeight: "700",
  }));

  function PieCenterLabelSize({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledTextSize x={left + width / 2} y={top + height / 1.3}>
        {children}
      </StyledTextSize>
    );
  }
  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 1.7}>
        {children}
      </StyledText>
    );
  }

  const [firestoreData, setFirestoreData] = useState(null);
  const [fileSizeInBytesImages, setFileSizeInBytesImages] = useState();
  const [fileSizeInBytesVideos, setFileSizeInBytesVideos] = useState();
  const [imagesCount, setImagesCount] = useState();
  const [videosCount, setVideosCount] = useState();
  const [totalSizeInGB, setTotalSizeGB] = useState();
  const [largestSubscriptionId, setLargestSubscriptionId] = useState();
  const [fullStorage, setFullStorage] = useState(false);
  const [entityId, setEntityId] = useState(null);
  const [isOrgUser, setIsOrgUser] = useState(null);
  const [businessStorage, setBusinessStorage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAuthData()
      .then((data) => {
        // Set entityId and isOrgUser based on the user's claims
        if (!data.claims.orgId) {
          setEntityId(currentUser.uid);
          setIsOrgUser(false);
        } else {
          setEntityId(data.claims.orgId);
          setIsOrgUser(true);
        }
      })
      .catch((error) => {
        console.error("Error getting authentication data:", error);
      });
  }, [currentUser]);

  useEffect(() => {
    if (isOrgUser) {
      firestoreRepository.getOrgData(entityId).then((orgData) => {
        setBusinessStorage(orgData.storageQuotaInGb);
        setFileSizeInBytesVideos(orgData.sizeVideos || 0);
        setFileSizeInBytesImages(orgData.sizeImages || 0);
        setLargestSubscriptionId(orgData.stripeSubscriptionId || 0);
        setIsLoading(false);
      });
    } else {
      firestoreRepository.getUserData(currentUser.uid).then((userData) => {
        setLargestSubscriptionId(
          Math.max(
            userData.stripeSubscriptionId || 0,
            userData.iosSubscriptionId || 0,
            userData.androidSubscriptionId || 0,
            userData.premiumAndroid ? 2 : 0,
            userData.premiumIOS ? 2 : 0
          )
        );
        if (userData.storageEmailLastThreshold === 1) {
          setFullStorage(true);
        }
        setFileSizeInBytesVideos(userData.sizeVideos || 0);
        setFileSizeInBytesImages(userData.sizeImages || 0);
        setFirestoreData(userData);
        setIsLoading(false);
      });
    }
  }, [isOrgUser, currentUser.uid, entityId]);

  useEffect(() => {
    if (isOrgUser) {
      setTotalSizeGB(businessStorage);
    } else if (largestSubscriptionId === 2 || largestSubscriptionId === 9) {
      setTotalSizeGB(500);
    } else if (largestSubscriptionId === 1) {
      setTotalSizeGB(10);
    } else if (largestSubscriptionId === 3) {
      setTotalSizeGB(2000);
    } else {
      setTotalSizeGB(0);
    }
  }, [largestSubscriptionId, businessStorage]);

  useEffect(() => {
    if (entityId === null || isOrgUser === null) {
      return;
    }
    firestoreRepository
      .countImagesInStorage(entityId, isOrgUser)
      .then((count) => {
        setImagesCount(count);
      });
    firestoreRepository
      .countVideosInStorage(entityId, isOrgUser)
      .then((count) => {
        setVideosCount(count);
      });
  }, [entityId]);

  const bytesToGB = (bytes) => {
    const gigabytes = bytes / (1024 * 1024 * 1024);
    return gigabytes.toFixed(2);
  };

  const usedSizeInGB = Number(
    (
      Number(bytesToGB(fileSizeInBytesVideos)) +
      Number(bytesToGB(fileSizeInBytesImages))
    ).toFixed(2)
  );

  const usedSizeInGBMini = Number(
    Number(bytesToGB(fileSizeInBytesImages)).toFixed(2)
  );

  const sizeLeftInGB =
    totalSizeInGB -
    (Number(bytesToGB(fileSizeInBytesVideos)) +
      Number(bytesToGB(fileSizeInBytesImages)));

  const sizeLeftInGBMini = totalSizeInGB - usedSizeInGBMini;
  const percentUsed = () => {
    const currentSumInGB =
      Number(bytesToGB(fileSizeInBytesVideos)) +
      Number(bytesToGB(fileSizeInBytesImages));

    const percentageSpaceUsed = (currentSumInGB / totalSizeInGB) * 100;
    return parseFloat(percentageSpaceUsed.toFixed(2));
  };

  const percentUsedMini = () => {
    const currentSumInGB = Number(bytesToGB(fileSizeInBytesImages));
    const percentageSpaceUsed = (currentSumInGB / totalSizeInGB) * 100;
    return parseFloat(percentageSpaceUsed.toFixed(2));
  };

  const [loading, setLoading] = useState(false);
  const [authData, setAuthData] = useState({});

  useEffect(() => {
    getAuthData()
      .then((data) => {
        setAuthData(data);
      })
      .catch((error) => {
        console.error("Error getting authentication data:", error);
      });
  }, [currentUser]);

  const createBillingPortalSession = async () => {
    if (!authData.token) {
      console.error("User is not authenticated.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/createStripeBillingPortalConfig`,

        {
          // Any data you want to send to the Cloud Function
          // For example, you can send an empty object if no data is needed.
        },
        {
          headers: {
            Authorization: `Bearer ${authData.token}`,
          },
        }
      );

      handleBillingPortalClick(response.data.portalSession);
    } catch (error) {
      console.error(error);
    } finally {
      // Hide the loading indicator whether the request succeeds or fails
      setLoading(false);
    }
  };

  const handleBillingPortalClick = (billingPortalUrl) => {
    if (billingPortalUrl) {
      window.location.href = billingPortalUrl;
    }
  };
  return (
    <Layout>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress style={{ color: "rgb(143, 79, 255)" }} />
        </div>
      ) : (
        <Container maxWidth="lg" style={{ marginTop: "2em" }}>
          {firestoreData && (
            <>
              <>
                <div style={{ fontWeight: "700", fontSize: "20px" }}>
                  Cloud Storage Summary
                </div>

                {largestSubscriptionId > 0 ? (
                  <>
                    <div
                      style={{
                        boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                        marginTop: "2em",
                        borderRadius: "10px",
                        padding: "20px",
                      }}
                    >
                      <Grid
                        container
                        xs={12}
                        style={{ justifyContent: "space-evenly" }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={5}
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          <>
                            {largestSubscriptionId === 1 ? (
                              <PieChart
                                series={[
                                  {
                                    data: [
                                      {
                                        id: 0,
                                        value: bytesToGB(fileSizeInBytesImages),
                                        label: "Photos, GB",
                                        color: "#3D3838",
                                      },

                                      {
                                        id: 1,
                                        value: sizeLeftInGBMini,
                                        label: "Cloud Storage left, GB",
                                        color: "rgba(204, 202, 199, 0.60)",
                                      },
                                    ],
                                    innerRadius: 125,
                                    outerRadius: 105,
                                    paddingAngle: 0,
                                    cornerRadius: 0,
                                    startAngle: -90,
                                    cy: 160,
                                    endAngle: 90,
                                  },
                                ]}
                                height={200}
                                slotProps={{
                                  legend: { hidden: true },
                                }}
                              >
                                <PieCenterLabelSize>
                                  {`${usedSizeInGBMini} GB / ${totalSizeInGB} GB`}
                                </PieCenterLabelSize>

                                <PieCenterLabel>
                                  {percentUsedMini()}% used
                                </PieCenterLabel>
                              </PieChart>
                            ) : (
                              <PieChart
                                series={[
                                  {
                                    data: [
                                      {
                                        id: 0,
                                        value: bytesToGB(fileSizeInBytesImages),
                                        label: "Photos, GB",
                                        color: "#3D3838",
                                      },
                                      {
                                        id: 1,
                                        value: bytesToGB(fileSizeInBytesVideos),
                                        label: "Videos, GB",
                                        color: "#8F4FFF",
                                      },
                                      {
                                        id: 2,
                                        value: sizeLeftInGB,
                                        label: "Cloud Storage left, GB",
                                        color: "rgba(204, 202, 199, 0.60)",
                                      },
                                    ],
                                    innerRadius: 125,
                                    outerRadius: 105,
                                    paddingAngle: 0,
                                    cornerRadius: 0,
                                    startAngle: -90,
                                    cy: 160,
                                    endAngle: 90,
                                  },
                                ]}
                                height={200}
                                slotProps={{
                                  legend: { hidden: true },
                                }}
                              >
                                {totalSizeInGB === 2000 ? (
                                  <PieCenterLabelSize>
                                    {`${usedSizeInGB} GB / 2 TB`}
                                  </PieCenterLabelSize>
                                ) : (
                                  <PieCenterLabelSize>
                                    {`${usedSizeInGB} GB / ${totalSizeInGB} GB`}
                                  </PieCenterLabelSize>
                                )}

                                <PieCenterLabel>
                                  {percentUsed()}% used
                                </PieCenterLabel>
                              </PieChart>
                            )}
                          </>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          sx={{ display: { xs: "block", sm: "none" } }}
                          style={{
                            textAlign: "center",
                            fontWeight: "700",
                            fontSize: "18px",
                          }}
                        >
                          {largestSubscriptionId === 1 ? (
                            <>
                              <div>
                                {`${usedSizeInGBMini} GB / ${totalSizeInGB} GB`}
                              </div>
                              {percentUsedMini()}% used{" "}
                            </>
                          ) : (
                            <>
                              {totalSizeInGB === 2000 ? (
                                <div> {`${usedSizeInGB} GB / 2 TB`}</div>
                              ) : (
                                <div>
                                  {`${usedSizeInGB} GB / ${totalSizeInGB} GB`}
                                </div>
                              )}
                              {percentUsed()}% used
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} md={5}>
                          {largestSubscriptionId === 1 ? (
                            <>
                              <hr
                                style={{
                                  border: "1px solid #EFEFEF",
                                }}
                              ></hr>
                              <div style={{ paddingTop: "1em" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Photos
                                    </div>
                                    <img
                                      src={camera}
                                      style={{
                                        marginLeft: "0.5em",
                                        width: "22px",
                                      }}
                                    ></img>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ fontWeight: "600" }}>
                                      {bytesToGB(fileSizeInBytesImages)} GB
                                    </div>
                                    <div>{imagesCount} photos</div>
                                  </div>
                                </div>
                              </div>
                              <div style={{ paddingTop: "1em" }}>
                                <hr
                                  style={{
                                    border: "1px solid #EFEFEF",
                                  }}
                                ></hr>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#999999",
                                        fontWeight: "600",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Videos
                                    </div>
                                    <img
                                      src={camera_gray}
                                      style={{ marginLeft: "0.5em" }}
                                    ></img>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#999999",
                                        fontWeight: "600",
                                      }}
                                    >
                                      0 GB
                                    </div>
                                    <div
                                      style={{
                                        color: "#999999",
                                      }}
                                    >
                                      0 videos
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  color: "#999999",
                                  marginTop: "1em",
                                  fontSize: "14px",
                                }}
                              >
                                Video uploads are available for Standard and
                                Premium Tiers.{" "}
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ paddingTop: "1em" }}>
                                <hr
                                  style={{
                                    border: "1px solid #EFEFEF",
                                  }}
                                ></hr>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#8F4FFF",
                                        fontWeight: "600",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Videos
                                    </div>
                                    <img
                                      src={video}
                                      style={{ marginLeft: "0.5em" }}
                                    ></img>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#8F4FFF",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {bytesToGB(fileSizeInBytesVideos)} GB
                                    </div>
                                    <div>{videosCount} videos</div>
                                  </div>
                                </div>
                              </div>
                              <div style={{ paddingTop: "1em" }}>
                                <hr
                                  style={{
                                    border: "1px solid #EFEFEF",
                                  }}
                                ></hr>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Photos
                                    </div>
                                    <img
                                      src={camera}
                                      style={{
                                        marginLeft: "0.5em",
                                        width: "22px",
                                      }}
                                    ></img>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ fontWeight: "600" }}>
                                      {bytesToGB(fileSizeInBytesImages)} GB
                                    </div>
                                    <div>{imagesCount} photos</div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                    {isOrgUser === false ? (
                      <div>
                        {largestSubscriptionId === 1 && !fullStorage ? (
                          <Grid
                            container
                            xs={12}
                            justifyContent={{
                              xs: "space-between",
                              md: "center",
                            }}
                            style={{
                              backgroundColor: "#E6D8FF",
                              padding: "15px",
                              borderRadius: "10px",
                              marginTop: "2em",
                              alignItems: "center",
                            }}
                          >
                            <Grid item xs={1} md={1}>
                              <img src={info}></img>
                            </Grid>
                            <Grid item xs={10} md={7}>
                              <div>
                                <div
                                  style={{
                                    fontWeight: "700",
                                    marginBottom: "0.5em",
                                  }}
                                >
                                  Access your videos on MicroREC Connect with
                                  ease!
                                </div>
                                <div>
                                  Upgrade your subscription for added benefits
                                  and expanded cloud storage.
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={2}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              marginTop={{ xs: "1em", md: "0" }}
                            >
                              <button
                                className="button_no_style"
                                style={{
                                  padding: "10px 40px",
                                  color: "white",
                                  backgroundColor: "#8F4FFF",
                                  borderRadius: "20px",
                                  fontSize: "16px",
                                  display: "flex",
                                  alignItems: "center",
                                  textDecoration: "none",
                                  fontWeight: "600",
                                  justifyContent: "center",
                                }}
                                onClick={createBillingPortalSession}
                              >
                                {loading ? (
                                  <div style={{ fontSize: "16px" }}>
                                    Loading...
                                  </div>
                                ) : (
                                  <div> Upgrade</div>
                                )}
                              </button>
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            {!fullStorage ? (
                              <Grid
                                container
                                xs={12}
                                justifyContent={{
                                  xs: "space-between",
                                  md: "center",
                                }}
                                style={{
                                  backgroundColor: "#E6D8FF",
                                  padding: "15px",
                                  borderRadius: "10px",
                                  marginTop: "2em",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item xs={1} md={1}>
                                  <img src={info}></img>
                                </Grid>
                                <Grid item xs={10} md={7}>
                                  <div>
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        marginBottom: "0.5em",
                                      }}
                                    >
                                      Do you need more cloud storage for
                                      MicroREC Connect?
                                    </div>
                                    <div>
                                      You can always upgrade your subscription
                                      to get more cloud storage and benefits.
                                    </div>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  marginTop={{ xs: "1em", md: "0" }}
                                >
                                  <button
                                    className="button_no_style"
                                    style={{
                                      padding: "10px 40px",
                                      color: "white",
                                      backgroundColor: "#8F4FFF",
                                      borderRadius: "20px",
                                      fontSize: "16px",
                                      display: "flex",
                                      alignItems: "center",
                                      textDecoration: "none",
                                      fontWeight: "600",
                                      justifyContent: "center",
                                    }}
                                    onClick={createBillingPortalSession}
                                  >
                                    {loading ? (
                                      <div style={{ fontSize: "16px" }}>
                                        Loading...
                                      </div>
                                    ) : (
                                      <div> Upgrade</div>
                                    )}
                                  </button>
                                </Grid>
                              </Grid>
                            ) : fullStorage && largestSubscriptionId === 3 ? (
                              <Grid
                                container
                                xs={12}
                                justifyContent={{
                                  xs: "space-between",
                                  md: "center",
                                }}
                                style={{
                                  backgroundColor: "#E6D8FF",
                                  padding: "15px",
                                  borderRadius: "10px",
                                  marginTop: "2em",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item xs={1} md={1}>
                                  <img src={error}></img>
                                </Grid>
                                <Grid item xs={10} md={7}>
                                  <div>
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        marginBottom: "0.5em",
                                      }}
                                    >
                                      Your cloud storage is full.
                                    </div>
                                    <div>
                                      To continue enjoying seamless data
                                      uploads, please contact our support team
                                      to upgrade your cloud storage plan.
                                    </div>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  marginTop={{ xs: "1em", md: "0" }}
                                >
                                  <a
                                    className="button_no_style"
                                    style={{
                                      padding: "10px 40px",
                                      color: "white",
                                      backgroundColor: "#8F4FFF",
                                      borderRadius: "20px",
                                      fontSize: "16px",
                                      display: "flex",
                                      alignItems: "center",
                                      textDecoration: "none",
                                      fontWeight: "600",
                                      justifyContent: "center",
                                    }}
                                    href="mailto:support@customsurgical.co"
                                  >
                                    <div>Contact us</div>
                                  </a>
                                </Grid>{" "}
                              </Grid>
                            ) : (
                              <Grid
                                container
                                xs={12}
                                justifyContent={{
                                  xs: "space-between",
                                  md: "center",
                                }}
                                style={{
                                  backgroundColor: "#E6D8FF",
                                  padding: "15px",
                                  borderRadius: "10px",
                                  marginTop: "2em",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item xs={1} md={1}>
                                  <img src={error}></img>
                                </Grid>
                                <Grid item xs={10} md={7}>
                                  <div>
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        marginBottom: "0.5em",
                                      }}
                                    >
                                      Your cloud storage is full.{" "}
                                    </div>
                                    <div>
                                      Maintain seamless data uploads by
                                      upgrading to a higher plan.
                                    </div>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  marginTop={{ xs: "1em", md: "0" }}
                                >
                                  <button
                                    className="button_no_style"
                                    style={{
                                      padding: "10px 40px",
                                      color: "white",
                                      backgroundColor: "#8F4FFF",
                                      borderRadius: "20px",
                                      fontSize: "16px",
                                      display: "flex",
                                      alignItems: "center",
                                      textDecoration: "none",
                                      fontWeight: "600",
                                      justifyContent: "center",
                                    }}
                                    onClick={createBillingPortalSession}
                                  >
                                    {loading ? (
                                      <div style={{ fontSize: "16px" }}>
                                        Loading...
                                      </div>
                                    ) : (
                                      <div> Upgrade</div>
                                    )}
                                  </button>
                                </Grid>
                              </Grid>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <Grid container item xs={10} md={5}>
                      <div
                        style={{
                          padding: "30px 0",
                          width: "100%",
                          borderRadius: "20px",
                          fontWeight: "700",
                          boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                          margin: "2.5em 0",
                          textAlign: "center",
                          color: "#999999",
                          height: "5em",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        You don’t have any subscription
                      </div>
                    </Grid>

                    <div
                      style={{
                        boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                        marginTop: "2em",
                        borderRadius: "10px",
                        marginTop: "0em",
                        position: "relative",
                      }}
                    >
                      {" "}
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          backdropFilter: "blur(3.8px)",
                          zIndex: "5",
                        }}
                      ></div>
                      <Grid container xs={12}>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          <PieChart
                            series={[
                              {
                                data: [
                                  {
                                    id: 0,
                                    value: 1,
                                    label: "",
                                    color: "#3D3838",
                                  },
                                  {
                                    id: 1,
                                    value: 2,
                                    label: "",
                                    color: "#8F4FFF",
                                  },
                                  {
                                    id: 2,
                                    value: 5,
                                    label: "",
                                    color: "rgba(204, 202, 199, 0.60)",
                                  },
                                ],
                                innerRadius: 125,
                                outerRadius: 105,
                                paddingAngle: 0,
                                cornerRadius: 0,
                                startAngle: -90,
                                cy: 160,
                                endAngle: 90,
                              },
                            ]}
                            // width={400}
                            height={200}
                            slotProps={{
                              legend: { hidden: true },
                            }}
                          >
                            <PieCenterLabelSize>
                              XXX GB /XXX GB
                            </PieCenterLabelSize>

                            <PieCenterLabel>XX% used</PieCenterLabel>
                          </PieChart>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <div style={{ paddingTop: "1em" }}>
                            <hr
                              style={{
                                border: "1px solid #EFEFEF",
                              }}
                            ></hr>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#8F4FFF",
                                    fontWeight: "600",
                                    fontSize: "18px",
                                  }}
                                >
                                  Videos
                                </div>
                                <img
                                  src={video}
                                  style={{ marginLeft: "0.5em" }}
                                ></img>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#8F4FFF",
                                    fontWeight: "600",
                                  }}
                                >
                                  XXX GB
                                </div>
                                <div>XXX videos</div>
                              </div>
                            </div>
                          </div>
                          <div style={{ paddingTop: "1em" }}>
                            <hr
                              style={{
                                border: "1px solid #EFEFEF",
                              }}
                            ></hr>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "18px",
                                  }}
                                >
                                  Photos
                                </div>
                                <img
                                  src={camera}
                                  style={{ marginLeft: "0.5em", width: "22px" }}
                                ></img>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ fontWeight: "600" }}>XXX GB</div>
                                <div>XXX photos</div>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>{" "}
                    </div>
                  </>
                )}
              </>
            </>
          )}
        </Container>
      )}
    </Layout>
  );
};

export default StoragePage;
