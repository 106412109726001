import React, { useContext, useEffect, useState } from "react";
import firestoreRepository, {
  getOrgData,
} from "../repositories/firestoreRepository";
import user from "../images/dashboard/user.svg";
import { Autocomplete, styled, TextField, Tooltip } from "@mui/material";
import { UploadContext } from "../components/App";
import { useAuth } from "../context/AuthContext";

const StyledTextField = styled(TextField)`
  & .MuiInputLabel-root {
    &.Mui-focused {
      color: #8f4fff;
    }
  }
  & .MuiOutlinedInput-root {
    box-shadow: 0px 2px 8px 0px #3a5c9d24;
    border-radius: 10px;
    &.Mui-focused fieldset {
      border-color: #8f4fff;
    }
  }
`;

const colorMap = {
  0: "#F0F0F2",
  1: "#BAA2D6",
  2: "#64BAAA",
  3: "#F2B56B",
  4: "#F1E7FF",
  5: "#C4FCF0",
  6: "#F2DEC4",
  7: "#8A6BBF",
  8: "#4C8076",
  9: "#F28705",
  10: "#8F4FFF",
  11: "#00C5B7",
  12: "#FFA100",
};

const getColorByClrIdx = (clrIdx) => colorMap[clrIdx] || "#F0F0F2";

export function AllOwners({ sessionOwner, entityId, wrtPermissions }) {
  const [userData, setUserData] = useState([]);
  const [owner, setOwner] = useState(null);
  const { selectedOwner, setSelectedOwner } = useContext(UploadContext);
  const [checkedOwnerModal, setCheckedOwnerModal] = useState(null);
  const [isAllowedToWrite, setIsAllowedToWrite] = useState(false);

  const { currentUser } = useAuth();
  const loadUserClaims = async (userId) => {
    const data = await firestoreRepository.getUserClaims(userId);
    return data;
  };

  useEffect(() => {
    const checkedOwner = sessionStorage.getItem("checkedOwnerModal");
    if (checkedOwner) {
      setCheckedOwnerModal(JSON.parse(checkedOwner));
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userClaims = await loadUserClaims(currentUser.uid);
        const orgData = await getOrgData(userClaims.orgId);
        const usersData = Object.keys(orgData.users)
          .map((userId) => ({
            id: userId,
            name: orgData.users[userId].name,
            clrIdx: orgData.users[userId].clrIdx,
            roleId: orgData.users[userId].roleId, // Include roleId in the mapping
          }))
          .filter((user) => user.roleId === 1); // Filter users with roleId 1;
        setUserData(usersData);
        if (orgData.users[sessionOwner]) {
          setOwner({
            name: orgData.users[sessionOwner].name,
            clrIdx: orgData.users[sessionOwner].clrIdx,
            id: sessionOwner,
          });
          const isAllowedToWrite =
            currentUser.uid === sessionOwner && wrtPermissions === 1;
          setIsAllowedToWrite(isAllowedToWrite);
          setSelectedOwner(sessionOwner);
        } else if (
          userClaims.roleId === 0 &&
          checkedOwnerModal &&
          checkedOwnerModal !== null
        ) {
          const selectedOwner = usersData.find(
            (user) => user.id === checkedOwnerModal
          );
          if (selectedOwner && sessionOwner !== "") {
            setOwner(selectedOwner);
            setSelectedOwner(selectedOwner.id);
            const isAllowedToWrite =
              currentUser.uid === sessionOwner && wrtPermissions === 1;
            setIsAllowedToWrite(isAllowedToWrite);
          } else {
            setOwner(null);
            setSelectedOwner(null);
            setIsAllowedToWrite(false);
          }
        } else if (entityId) {
          const selectedOwner = usersData.find(
            (user) => user.id === currentUser.uid
          );
          if (selectedOwner) {
            setOwner(selectedOwner);
            setSelectedOwner(selectedOwner.id);
            const isAllowedToWrite =
              currentUser.uid === selectedOwner.id && wrtPermissions === 1;
            setIsAllowedToWrite(isAllowedToWrite);
          }
        }
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    fetchData();
  }, [entityId, checkedOwnerModal]);

  const handleSelectionChange = (event, newValue) => {
    setOwner(newValue);
    setSelectedOwner(newValue ? newValue.id : null);
    sessionStorage.setItem("checkedOwnerModal", JSON.stringify(newValue.id));
  };

  const handleBeforeUnload = () => {
    sessionStorage.removeItem("checkedOwnerModal");
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {owner ? (
        owner.name === "Unassigned" ? (
          <div
            style={{
              width: "35px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: "#F0F0F2",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: "500",
              marginRight: "10px",
            }}
          >
            <img src={user} style={{ width: "20px" }} />
          </div>
        ) : (
          <div
            style={{
              width: "35px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: getColorByClrIdx(owner.clrIdx),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: "500",
              marginRight: "10px",
            }}
          >
            {owner.name
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </div>
        )
      ) : null}
      <Tooltip
        title={
          isAllowedToWrite
            ? "You don't have the required permissions to edit the owner"
            : ""
        }
      >
        <span style={{ width: "100%" }}>
          <Autocomplete
            options={[
              { name: "Unassigned", clrIdx: null, id: "" },
              ...userData,
            ].sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            style={{
              width: "100%",
              resize: "none",
              display: "flex",
              marginTop: "1em",
              marginBottom: "1em",
            }}
            onChange={handleSelectionChange}
            value={owner}
            disabled={isAllowedToWrite} // Disable Autocomplete if isAllowedToWrite is true
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label={
                  !owner || owner.name === "Unassigned"
                    ? "Select session owner"
                    : ""
                }
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                {option.clrIdx !== null ? (
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: getColorByClrIdx(option.clrIdx),
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "500",
                      marginRight: "10px",
                    }}
                  >
                    {option.name
                      .split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </div>
                ) : (
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#F0F0F2",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "500",
                      marginRight: "10px",
                    }}
                  >
                    <img src={user} style={{ width: "20px" }} />
                  </div>
                )}
                {option.name}
              </li>
            )}
          />
        </span>
      </Tooltip>
    </div>
  );
}

export function Owner({ uid }) {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const { selectedOwner, setSelectedOwner } = useContext(UploadContext);

  const loadUserClaims = async (userId) => {
    const data = await firestoreRepository.getUserClaims(userId);
    return data;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userClaims = await loadUserClaims(currentUser.uid);
        const orgData = await getOrgData(userClaims.orgId);

        if (orgData.users[uid] && orgData.users[uid].roleId === 1) {
          setUserData({
            name: orgData.users[uid].name,
            clrIdx: orgData.users[uid].clrIdx,
            uid: uid,
          });
        } else {
          setUserData({
            name: "Unassigned",
            clrIdx: null,
            uid: uid,
          });
        }
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    fetchData();
  }, [uid]);

  return (
    userData && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tooltip title={userData.name} arrow>
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: getColorByClrIdx(userData.clrIdx),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {userData.name === "Unassigned" ? (
              <img src={user} style={{ width: "20px" }} />
            ) : (
              <>
                {userData.name
                  .split(" ")
                  .map((name) => name[0])
                  .join("")}
              </>
            )}
          </div>
        </Tooltip>
      </div>
    )
  );
}

export function SessionOwner({ uid }) {
  const { currentUser } = useAuth();

  const [userData, setUserData] = useState(null);
  const loadUserClaims = async (userId) => {
    const data = await firestoreRepository.getUserClaims(userId);
    return data;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userClaims = await loadUserClaims(currentUser.uid);
        const orgData = await getOrgData(userClaims.orgId);
        if (
          orgData.users &&
          orgData.users[uid] &&
          orgData.users[uid].roleId === 1
        ) {
          setUserData({
            name: orgData.users[uid].name,
            clrIdx: orgData.users[uid].clrIdx,
            uid: uid,
          });
        }
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    fetchData();
  }, [uid]);

  return userData ? (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          margin: 0,
        }}
      >
        <div
          style={{
            width: "35px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: getColorByClrIdx(userData.clrIdx),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {userData.name
            .split(" ")
            .map((name) => name[0])
            .join("")}
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "10px",
            fontWeight: "500",
            border: "1px solid #efefef",
            boxShadow: "0px 2px 8px rgba(58, 92, 157, 0.14)",
            height: "4vh",
            borderRadius: "10px",
            width: "100%",
            marginLeft: "10px",
          }}
        >
          Dr. {userData.name}
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          margin: 0,
        }}
      >
        <div
          style={{
            width: "35px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: "#F0F0F2",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          <img src={user} style={{ width: "20px" }} />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "10px",
            fontWeight: "500",
            border: "1px solid #efefef",
            boxShadow: "0px 2px 8px rgba(58, 92, 157, 0.14)",
            height: "4vh",
            borderRadius: "10px",
            width: "100%",
            marginLeft: "10px",
          }}
        >
          Unassigned
        </div>
      </div>
    </>
  );
}
